import { Link } from "gatsby";
import React, { Component } from "react";
import { BrandButton } from "../../global";
import {
  ActionsContainer, Brand, Nav,
  NavItem, NavListWrapper, StyledContainer
} from "./style";


const NAV_ITEMS = ["Solutions", "Pricing"];

export default class LandingPageNavigation extends Component {
  state = {
    mobileMenuOpen: false,
    hasScrolled: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true });
    } else {
      this.setState({ hasScrolled: false });
    }
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = (item) => {
    if (item === "Ranger") {
      item = "";
    }
    return (
      <Link to={`/${item.toLowerCase()}/`} onClick={this.closeMobileMenu}>
        {item}
      </Link>
    );
  };

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      {/* <Scrollspy
        items={NAV_ITEMS.map((item) => item.toLowerCase())}
        // currentClassName="active"
        mobile={mobile}
        offset={-64}
      > */}
      {NAV_ITEMS.map((navItem) => (
        <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
      ))}
      {/* </Scrollspy> */}
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            {/* <Scrollspy
              offset={-64}
              item={["top"]}
              // currentClassName="active"
            > */}
            RANGER
            {/* </Scrollspy> */}
          </Brand>
          {/* <Mobile>
            <button
              onClick={this.toggleMobileMenu}
              style={{ color: "black", background: "none" }}
            >
              {this.state.mobileMenuOpen ? (
                <X size={24} alt="close menu" />
              ) : (
                <Menu size={24} alt="open menu" />
              )}
            </button>
          </Mobile> */}

          {/* <Mobile hide>{this.getNavList({})}</Mobile> */}
          <ActionsContainer>
            <Link
              to={this.props.ctaLink || "/signup/"}
              style={{ textDecoration: "none" }}
            >
              <BrandButton>{this.props.ctaBtnTxt || "Sign Up"}</BrandButton>
            </Link>
          </ActionsContainer>
        </StyledContainer>
        {/* <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile> */}
      </Nav>
    );
  }
}
