import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import LandingPageNavigation from "../components/common/navigation/landing-page-navigation";
import { BodyText, Container, Section } from "../components/global";
import CtaFooter from "../components/sections/cta-footer";
import Footer from "../components/sections/footer";



export default ({
  data: {
    content: {
      frontmatter: {
        path,
        title,
        description,
        date,
        image,
        cta,
        ctaLink,
        ctaBtnTxt,
        blurb,
      },
      body,
    },
  },
}) => (
  <Layout>
    <SEO
      title={title || "Get Ranger"}
      description={
        description ||
        "Ranger is an Excel Add-in and plugin for Excel that provides a no-code solution for protecting, centralizing and storing Excel data by connecting it to the cloud."
      }
    />
    <LandingPageNavigation cta={cta} ctaLink={ctaLink} ctaBtnTxt={ctaBtnTxt} />
    <Container>
      <Section>
        <BodyText>
          <MDXRenderer>{body}</MDXRenderer>
        </BodyText>
      </Section>
    </Container>
    <CtaFooter
      cta={cta || undefined}
      ctaLink={ctaLink || undefined}
      ctaBtnTxt={ctaBtnTxt || undefined}
    />
    <Footer />
  </Layout>
);

export const query = graphql`
  query($id: String) {
    content: mdx(id: { eq: $id }) {
      frontmatter {
        path
        title
        description
        date
        image
        cta
        ctaLink
        blurb
      }
      body
    }
    # other: allMdx(
    #   filter: { fileAbsolutePath: { regex: "/content/content-pages/" } }
    # ) {
    #   edges {
    #     node {
    #       frontmatter {
    #         title
    #         slug
    #         path
    #         icon
    #         price
    #         blurb
    #       }
    #       excerpt
    #     }
    #   }
    # }
  }
`;
